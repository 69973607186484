import { serverApi, getCookieValue } from "../config";

export const Logout = () => {
  try {
    const request = serverApi("POST", `snUsers/logout`, "", "", {
      security: true,
      token: getCookieValue("access_token"),
    });
    return request;
  } catch (err) {
    console.log(err);
  }
};
