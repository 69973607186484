import React from "react";
import { Router, Route, Switch } from "react-router-dom";
import history from "./lib/reactRouterLib/history";
import NavLogin from "./routes/Generic-Login";
import RedirectionView from "./routes/RedirectionView";
import ResetPassword from "./routes/ResetPassword";
import ForgotPassword from "./routes/ForgotPassword";
import SingupPage from "./routes/Signup";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import "./App.scss";
import NAVLogout from "routes/Logout";

export const root = "/auth";

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4ac2d0",
    },
    secondary: {
      main: "#4ac2d0",
    },
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: "0.8rem",
      },
    },
  },
});

const Empty = ({ children }) => {
  return <div>{children}</div>;
};

const Application = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Router history={history}>
        <Empty>
          <Switch>
            <Route path="/auth" component={NavLogin} exact />
            <Route path="/auth/logout" component={NAVLogout} />
            <Route path="/auth/forgot-password" component={ForgotPassword} />
            <Route path="/auth/views" component={RedirectionView} />
            <Route path="/auth/reset-password" component={ResetPassword} />
            <Route path="/auth/signup" component={SingupPage} />
            <Route path="/auth/:path*" component={NavLogin} />
          </Switch>
        </Empty>
      </Router>
    </MuiThemeProvider>
  );
};

export default Application;
