import React, { useState } from "react";
import { serverApi } from "../../networking/config";
import Logo from "components/Logo/Logo";
import { root } from "ApplicationRoutes";
import { IconButton, Paper } from "@material-ui/core";
import MessageBox from "components/MessageBox";
import PrimaryTextField from "components/PrimaryTextField/PrimaryTextField";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";

const SingupPage = () => {
  const history = useHistory();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const validate = (values) => {
    let errors = {};

    if (!values.name) {
      errors.name = {
        hasError: true,
        errorMessage: "Name is required",
      };
    } else {
      errors.name = {
        hasError: false,
        errorMessage: "",
      };
    }

    if (!values.email) {
      errors.email = {
        hasError: true,
        errorMessage: "Email is required",
      };
    } else if (!/\S+@\S+\.\S+/.test(values.email)) {
      errors.email = {
        hasError: true,
        errorMessage: "Email address is invalid",
      };
    } else {
      errors.email = {
        hasError: false,
        errorMessage: "",
      };
    }

    return errors;
  };

  const handleChange = (event) => {
    event.persist();
    const { name, value } = event.target;
    setValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: {
        hasError: false,
        errorMessage: "",
      },
    }));
  };

  const handleSubmit = async (event) => {
    if (event) event.preventDefault();
    setIsSubmitting(true);
    setErrors({});
    const validationErrors = validate(values);
    const hasErrors = Object.values(validationErrors).some(
      (error) => error.hasError
    );
    if (hasErrors) {
      setErrors(validationErrors);
      setIsSubmitting(false);
    } else {
      try {
        await serverApi(
          "POST",
          `snUsers/signup`,
          "",
          {
            email: values.email,
            name: values.name,
            message: values.message,
            company: values.company,
          },
          ""
        );

        setIsSuccess(true);
      } catch (error) {
        setErrors({});
        setIsSubmitting(false);
        setErrorMessage(
          "Something went wrong. Please check the provided email address and try again."
        );
      }
    }
  };

  return (
    <div className="centered-box">
      <Logo root={root} />
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <Paper classes={{ root: "box-container" }}>
          <div className="box-title">
            <IconButton onClick={() => history.goBack(null)}>
              <ArrowBackIcon />
            </IconButton>
            <span>Sign Up</span>
          </div>
          {isSuccess ? (
            <div className="success-panel">
              <CheckCircleOutlineIcon />
              <div className="helper-text">
                <span
                  role="img"
                  aria-label="Congratulations! Party popper and confetti"
                >
                  🎉
                </span>{" "}
                You're now one step closer to joining us. Keep an eye on your
                inbox for your login details. Thank you!
              </div>
            </div>
          ) : (
            <>
              {errorMessage && <MessageBox text={errorMessage} />}
              <div className="helper-text mb-5"></div>
              <PrimaryTextField
                label="First and last name"
                rootClassName="mb-20 mt-10"
                onChange={handleChange}
                name="name"
                value={values.name || ""}
                hasError={errors.name && errors.name.hasError}
                errorMessage={errors.name && errors.name.errorMessage}
                fullWidth
              />
              <PrimaryTextField
                label={
                  errors.email && errors.email.hasError
                    ? errors.email.errorMessage
                    : "Email"
                }
                rootClassName="mb-20 mt-10"
                onChange={handleChange}
                name="email"
                value={values.email || ""}
                hasError={errors.email && errors.email.hasError}
                fullWidth
              />
              <PrimaryTextField
                label="Company"
                rootClassName="mb-20 mt-10"
                onChange={handleChange}
                name="company"
                value={values.company || ""}
                fullWidth
              />
              <PrimaryTextField
                label="Message"
                rootClassName="mb-20 mt-10"
                onChange={handleChange}
                name="message"
                value={values.message || ""}
                fullWidth
                multiline
              />
              <div className="box-buttons-wrap">
                <PrimaryButton
                  type="submit"
                  disabled={
                    isSubmitting ||
                    !values.name ||
                    !values.email ||
                    (errors.name && errors.name.hasError) ||
                    (errors.email && errors.email.hasError)
                  }
                  fullWidth
                >
                  Sign up
                </PrimaryButton>
              </div>
            </>
          )}
        </Paper>
      </form>
    </div>
  );
};

export default SingupPage;
