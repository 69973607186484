import React, { useEffect, useState } from "react";
import {
  addAccessTokenCookie,
  removeAccessTokenCookie,
  serverApi,
} from "../../networking/config";
import { useHistory } from "react-router-dom";
import { Paper } from "@material-ui/core";
import Logo from "components/Logo/Logo";
import { root } from "ApplicationRoutes";
import PrimaryTextField from "components/PrimaryTextField/PrimaryTextField";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import MessageBox from "components/MessageBox";
import { Logout } from "../../networking/APIs/Logout";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

const rolesSupported = {
  OPERATOR: 6,
  AGENT: 121,
  CLIENT: 7,
  ACCOUNTANT: 3,
  WAREHOUSE: 61,
  SUPPLIER: 302,
  TEAMLEADER: 281,
  CUSTOMSBROKER: 303,
  QUOTESUPERVISOR: 304,
  CLIENTMB: 101,
  BACKOFFICE: 305,
  GUESTSUPPLIER: 221,
  BACKOFFICEMASTERDATAREAD: 421,
  BACKOFFICEMASTERDATA: 422,
  POHANDLER: 306,
};

const NavLogin = (props) => {
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async () => {
    setErrorMessage("");

    let hasError = false;

    if (username === "" || password === "") {
      hasError = true;
      setErrorMessage("Username and password are required.");
    }

    if (hasError) {
      return;
    }

    try {
      let loginRequest = await serverApi(
        "POST",
        `snUsers/login`,
        "",
        {
          username,
          password,
        },
        ""
      );

      if (loginRequest?.status === 200) {
        addAccessTokenCookie(loginRequest.data.id);
        const redirectionUrl = new URLSearchParams(props.location.search).get(
          "url"
        );
        if (redirectionUrl) {
          window.location.href = redirectionUrl;
        } else {
          let userResponse = await serverApi(
            "GET",
            "snUsers/authenticated/user",
            "",
            "",
            { security: true, token: loginRequest.data.id }
          );

          const user = userResponse.data.user;

          if (userResponse?.status === 200) {
            // check if user roles are supported
            const roleIds = user.roles.map((x) => x.id);
            const roleIdsSupported = Object.values(rolesSupported);
            if (!roleIds.some((x) => roleIdsSupported.indexOf(x) > -1)) {
              setErrorMessage(
                "Invalid login. Please contact your system administrator."
              );
              Logout();
              removeAccessTokenCookie();
              return;
            }

            const destination = user.destination;
            if (destination) {
              window.location.href = destination;
            } else {
              let targetBaseUrl =
                window.location.protocol + "//" + window.location.host;
              if (targetBaseUrl.includes("7002")) {
                targetBaseUrl = targetBaseUrl.replace("7002", "1009");
              }
              window.location.href = targetBaseUrl + "/v2";
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      setErrorMessage("Username or password is not valid.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault();
      handleLogin();
    }
  };

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    removeAccessTokenCookie();
  }, []);

  return (
    <>
      <div className="centered-box">
        <Logo root={root} />
        <form noValidate autoComplete="off">
          <Paper classes={{ root: "box-container" }}>
            <div className="box-title">Sign In</div>
            {errorMessage && <MessageBox text={errorMessage} />}
            <PrimaryTextField
              label="Username"
              type="text"
              rootClassName="mb-20 mt-10"
              onChange={(e) => setUsername(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e)}
              value={username}
              fullWidth
            />
            <PrimaryTextField
              label="Password"
              rootClassName="password-textfield"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e)}
              type={showPassword ? "text" : "password"}
              value={password}
              fullWidth
              iconButton2={{
                icon: !showPassword ? <Visibility /> : <VisibilityOff />,
                onClick: () => {
                  handleShowPassword();
                },
                tooltip: !showPassword ? "Show Password" : "Hide Password",
              }}
            />
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div
                className="forgot-your-password mb-30"
                onClick={() => {
                  history.push("/auth/signup");
                }}
              >
                <span>Don't have an account?</span>
              </div>
              <div
                className="forgot-your-password mb-30"
                onClick={() => {
                  history.push("/auth/forgot-password");
                }}
              >
                <span>Forgot your password?</span>
              </div>
            </div>
            <div className="box-buttons-wrap">
              <PrimaryButton
                onClick={() => handleLogin()}
                disabled={username.trim() === "" || password.trim() === ""}
                fullWidth
              >
                Login
              </PrimaryButton>
            </div>
            <div
              style={{
                textAlign: "center",
                marginTop: "10px",
                marginBottom: "-25px",
                color: "#ccc",
              }}
            >
              <a
                className="Navigator-App"
                href="https://navigator-terms.s3.eu-west-2.amazonaws.com/HAF_privacy_policy.html"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: "none",
                  color: "#0f4952",
                  fontHeight: "14px",
                  fontSize: "13px",
                }}
              >
                Terms Of Service
              </a>
            </div>
          </Paper>
        </form>

        <div className="footer-info">
          <a
            className="google-badge"
            target="_blank"
            rel="noopener noreferrer"
            href="https://play.google.com/store/apps/details?id=com.swiftmarine.navigatormobile"
          >
            <span>Get it on google play</span>
          </a>
        </div>
      </div>
    </>
  );
};

export default NavLogin;
