import { Button } from "@material-ui/core";
import React from "react";
import "./PrimaryButton.scss";

export default function PrimaryButton(props) {
  return (
    <Button
      disableElevation
      color="primary"
      variant="contained"
      classes={{ label: "sm-primary-button-label", ...props.classes }}
      {...props}
    >
      {props.children}
    </Button>
  );
}
