import React from "react";
import logo from "assets/img/logo.png";
import navigatorImage from "assets/img/navigator.svg";
import "./Logo.scss";

function Logo(props) {
  const { root } = props;
  return (
    <div className="logo">
      <div className="logo-content">
        <a href={root} className="logo-img">
          <img src={logo} alt="logo" />
        </a>
        <a href={root} className="logo-text">
          <img src={navigatorImage} alt="Navigator" />
        </a>
      </div>
    </div>
  );
}

export default Logo;
