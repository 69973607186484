import { createBrowserHistory } from 'history'
import compose from 'ramda/src/compose'
import join from 'ramda/src/join'
import ifElse from 'ramda/src/ifElse'
import always from 'ramda/src/always'
import isNil from 'ramda/src/isNil'
import slice from 'ramda/src/slice'
import append from 'ramda/src/append'
import split from 'ramda/src/split'

// ========================================================
// Browser History Setup
// ========================================================

const history = createBrowserHistory();

const currentPathname = () => history.getCurrentLocation().pathname;

export const replace = history.replace.bind(history);
export const push = history.push.bind(history);
export const goBack = history.goBack.bind(history)

// replace or push to change only url params
// const changeParams = (fn) => (query) => fn({ pathname: currentPathname(), search: `?${params(query)}` })
// export const replaceParams = changeParams(replace)
// export const pushParams = changeParams(push)

const excludeLast = slice(0, -1)
const replaceLastPath = path => compose(
  join('/'), // rejoin with / to get a string path
  // if path is nil return current path without the last part,
  // otherwise replace last part with path
  ifElse(always(isNil(path)), excludeLast, compose(append(path), excludeLast)),
  split('/'), // split on / to get path parts
  currentPathname, // get current pathname
)();
const changeCurrentPath = fn => path => fn({ pathname: replaceLastPath(path) });
export const replaceCurrentPath = changeCurrentPath(replace);
export const pushCurrentPath = changeCurrentPath(push);

export default history;
