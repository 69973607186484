import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { serverApi } from "../../networking/config";
import { root } from "ApplicationRoutes";
import { Paper } from "@material-ui/core";
import Logo from "components/Logo/Logo";
import MessageBox from "components/MessageBox";
import PrimaryTextField from "components/PrimaryTextField/PrimaryTextField";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import DoneIcon from "@material-ui/icons/Done";
import CloseIcon from "@material-ui/icons/Close";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";

const ResetPassword = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const [tokenExpired, setTokenExpired] = useState(true);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();

  const hasMinimum12Chars = newPassword.length >= 12;
  const hasAtLeast1Lowercase = /[a-z]/.test(newPassword);
  const hasAtLeast1Uppercase = /[A-Z]/.test(newPassword);
  const hasAtLeast2Digits = /\d{2}/.test(newPassword);
  const containsWhitespace = newPassword.indexOf(" ") > -1;

  useEffect(() => {
    const checkToken = async () => {
      let token = new URLSearchParams(window.location.search).get(
        "access_token"
      );
      try {
        await serverApi("GET", "snUsers/authenticated/user", "", "", {
          security: true,
          token: token,
        });

        setTokenExpired(false);
      } catch (e) {
        setErrorMessage("Link is expired.");
      }
    };
    checkToken();
  }, []);

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault();
      handleResetPassword();
    }
  };

  const handleResetPassword = async () => {
    let hasError = false;

    if (newPassword === "" && confirmPassword === "") {
      setErrorMessage("All fields are required.");
      hasError = true;
    } else if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords do not match.");
      hasError = true;
    } else if (
      !hasMinimum12Chars ||
      !hasAtLeast1Uppercase ||
      !hasAtLeast1Lowercase ||
      !hasAtLeast2Digits
    ) {
      setErrorMessage("Password does not meet the requirements.");
      hasError = true;
    } else if (containsWhitespace) {
      setErrorMessage("Password should not contain whitespaces.");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      let temporaryToken = new URLSearchParams(window.location.search).get(
        "access_token"
      );

      await serverApi(
        "POST",
        `snUsers/reset-password`,
        "",
        {
          newPassword: newPassword,
        },
        {
          security: true,
          token: temporaryToken,
        }
      );

      setFormSubmitted(true);
    } catch (error) {
      console.log(error);
      setErrorMessage("Something went wrong. Please try again.");
    }
  };

  return (
    <>
      <div className="centered-box">
        <Logo root={root} />
        <Paper classes={{ root: "box-container" }}>
          <div className="box-title">Reset Password</div>
          {formSubmitted && (
            <div className="success-panel">
              <CheckCircleOutlineIcon />
              <div className="helper-text">
                Your password was changed. Please use the new password to sign
                in.
              </div>
              <div className="box-buttons-wrap">
                <PrimaryButton
                  onClick={() => {
                    history.push("/auth");
                  }}
                  fullWidth
                >
                  Go To Sign In
                </PrimaryButton>
              </div>
            </div>
          )}
          {!formSubmitted && (
            <>
              {errorMessage && <MessageBox text={errorMessage} />}
              {!tokenExpired && (
                <>
                  <PrimaryTextField
                    type="password"
                    label="New Password"
                    rootClassName="mt-10"
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    onKeyPress={(e) => handleKeyPress(e)}
                    value={newPassword}
                    fullWidth
                    autoComplete="off"
                  />
                  {newPassword.length > 0 && (
                    <div className="check-wrap">
                      <div
                        className={`check-row ${
                          hasMinimum12Chars ? "valid" : ""
                        }`}
                      >
                        {hasMinimum12Chars ? <DoneIcon /> : <CloseIcon />}
                        <span className="check-text">
                          Minimum 12 characters
                        </span>
                      </div>
                      <div
                        className={`check-row ${
                          hasAtLeast1Uppercase ? "valid" : ""
                        }`}
                      >
                        {hasAtLeast1Uppercase ? <DoneIcon /> : <CloseIcon />}
                        <span className="check-text">
                          At least 1 uppercase letter
                        </span>
                      </div>
                      <div
                        className={`check-row ${
                          hasAtLeast1Lowercase ? "valid" : ""
                        }`}
                      >
                        {hasAtLeast1Lowercase ? <DoneIcon /> : <CloseIcon />}
                        <span className="check-text">
                          At least 1 lowercase letter
                        </span>
                      </div>
                      <div
                        className={`check-row ${
                          hasAtLeast2Digits ? "valid" : ""
                        }`}
                      >
                        {hasAtLeast2Digits ? <DoneIcon /> : <CloseIcon />}
                        <span className="check-text">At least 2 digits</span>
                      </div>
                    </div>
                  )}
                  <PrimaryTextField
                    type="password"
                    label="Confirm New Password"
                    rootClassName="mb-20 mt-10"
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onKeyPress={(e) => handleKeyPress(e)}
                    value={confirmPassword}
                    fullWidth
                    textFieldProps={{
                      autoComplete: "off",
                    }}
                  />
                  <div className="box-buttons-wrap">
                    <PrimaryButton
                      onClick={() => handleResetPassword()}
                      disabled={
                        newPassword.trim() === "" ||
                        confirmPassword.trim() === ""
                      }
                      fullWidth
                    >
                      Reset
                    </PrimaryButton>
                  </div>
                </>
              )}
            </>
          )}
        </Paper>
      </div>
    </>
  );
};

export default ResetPassword;
