import React, { useState } from "react";
import { serverApi } from "../../networking/config";
import Logo from "components/Logo/Logo";
import { root } from "ApplicationRoutes";
import { IconButton, Paper } from "@material-ui/core";
import MessageBox from "components/MessageBox";
import PrimaryTextField from "components/PrimaryTextField/PrimaryTextField";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { useHistory } from "react-router";

const ForgotPassword = (props) => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const isEmailValid = (email) => {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const changeEmail = (e) => {
    let value = e.target.value;
    setEmail(value);
  };

  const handleKeyPress = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      e.preventDefault();
      handleForgotPassword();
    }
  };

  const handleForgotPassword = async () => {
    setErrorMessage("");

    let hasError = false;

    if (email.trim() === "") {
      setErrorMessage("Email is required.");
      hasError = true;
    } else if (!isEmailValid(email)) {
      setErrorMessage("Invalid email.");
      hasError = true;
    }

    if (hasError) {
      return;
    }

    try {
      await serverApi(
        "POST",
        `snUsers/reset`,
        "",
        {
          email: email,
        },
        ""
      );

      setEmailSent(true);
    } catch (error) {
      console.log(error);
      setErrorMessage(
        "Something went wrong. Please check the provided email address and try again."
      );
    }
  };

  return (
    <div className="centered-box">
      <Logo root={root} />
      <form noValidate autoComplete="off">
        <Paper classes={{ root: "box-container" }}>
          <div className="box-title">
            <IconButton
              onClick={() => {
                history.goBack(null);
              }}
            >
              <ArrowBackIcon />
            </IconButton>
            <span>Forgot Password</span>
          </div>
          {emailSent && (
            <div className="success-panel">
              <CheckCircleOutlineIcon />
              <div className="helper-text">
                An e-mail was sent to {email}. Please check your e-mail account
                and follow the instructions in the email.
              </div>
            </div>
          )}
          {!emailSent && (
            <>
              {errorMessage && <MessageBox text={errorMessage} />}
              <div className="helper-text mb-5">
                Enter your email address. You will receive an email with
                instructions to reset your password.
              </div>
              <PrimaryTextField
                label="Email"
                rootClassName="mb-20 mt-10"
                onChange={(e) => changeEmail(e)}
                onKeyPress={(e) => handleKeyPress(e)}
                value={email}
                fullWidth
              />
              <div className="box-buttons-wrap">
                <PrimaryButton
                  onClick={() => handleForgotPassword()}
                  disabled={(email || "").trim().length === 0}
                  fullWidth
                >
                  Send
                </PrimaryButton>
              </div>
            </>
          )}
        </Paper>
      </form>
    </div>
  );
};

export default ForgotPassword;
