import Axios from "axios";
import _ from "lodash";
import { loadProgressBar } from "axios-progress-bar";
import "./nprogress.css";

export const getCookieValue = (a) => {
  var b = document.cookie.match("(^|;)\\s*" + a + "\\s*=\\s*([^;]+)");
  return b ? b.pop() : "";
};

export const isDev = window?.location?.hostname === "localhost";

export const removeAccessTokenCookie = () => {
  document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;domain=${
    isDev ? "localhost" : ".swift-navigator.com"
  }; path=/;`;
};

export const addAccessTokenCookie = (accessToken) => {
  document.cookie = `access_token=${accessToken};domain=${
    isDev ? "localhost" : ".swift-navigator.com"
  };path=/`;
};

const axiosOptions = (method, url, params, data, options, contentType) => {
  /**
   * Configure API Endpoints Dynamically based on the fact that different environments
   * will be accessed by different hostnames in the browser
   */
  let final = null;
  let environmentLocation =
    window && window.location && window.location.hostname;

  if (environmentLocation === "localhost") {
    final = "http://localhost:3000/api";
  }

  //existing dev2 server
  if (environmentLocation === "dev2.swift-navigator.com") {
    final = "https://dev2.swift-navigator.com:443/api";
  } else if (environmentLocation === "prod2.swift-navigator.com") {
    final = "https://prod2.swift-navigator.com/api";
  } else if (environmentLocation === "uat2.swift-navigator.com") {
    final = "https://uat2.swift-navigator.com/api";
  }

  //backup of dev2 server (works only for backoffice app right now due to model-config that only backoffice models are exposed REST)
  if (environmentLocation === "dev-ds.swift-navigator.com") {
    final = "https://dev-ds.swift-navigator.com:443/api";
  } else if (environmentLocation === "uat-ds.swift-navigator.com") {
    final = "https://uat-ds.swift-navigator.com/api";
  } else if (environmentLocation === "prod-ds.swift-navigator.com") {
    final = "https://prod-ds.swift-navigator.com/api";
  }

  const BASE_URL = final;
  let finalObj = {
    method: method,
    url: `${BASE_URL}/${url}`,
    params: params,
    data: data,
    headers: !options.security
      ? {
          Accept: "application/json",
          "Content-Type": contentType || "application/json",
          Authorization: `${localStorage.getItem("access_token")}`,
        }
      : {
          Accept: "application/json",
          "Content-Type": contentType || "application/json",
          Authorization: options && options.token,
        },
    timeout: 120000,
  };

  return _.extend(finalObj, options || {});
};

const serverApi = async (method, url, params, data, options, contentType) => {
  loadProgressBar();

  const request = Axios(
    axiosOptions(method, url, params, data, options, contentType)
  );

  return await request
    .then((response) => {
      return response;
    })
    .catch((error) => {
      if (error && error.response && error.response.status === 401) {
        localStorage.clear();
      }

      throw error;
    });
};

export { serverApi };
