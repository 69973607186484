import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";
import React from "react";
import "./PrimaryTextField.scss";

export default function PrimaryTextField({
  iconButton,
  iconButton2,
  hasError,
  errorMessage,
  iconAfter,
  rootClassName,
  multiline,
  rowsMax,
  helperText,
  ...textFieldProps
}) {
  const value = textFieldProps.value || "";
  let textfieldClass = "";
  if (value) {
    if (iconButton?.showOnHover) {
      textfieldClass = "show-icon-on-hover";
    } else {
      textfieldClass = "show-icon-always";
    }
  } else {
    textfieldClass = "show-icon-always";
  }
  return (
    <FormControl fullWidth variant="outlined" error={hasError}>
      <div>
        <TextField
          {...textFieldProps}
          multiline={multiline}
          helperText={helperText}
          fullWidth
          error={hasError}
          value={value}
          type={textFieldProps.type || "text"}
          variant="outlined"
          classes={{
            root: `sm-outlined-input-small ${textfieldClass} ${
              rootClassName || ""
            }`,
          }}
          InputProps={{
            endAdornment: (iconButton || iconButton2) && (
              <>
                <Tooltip title={iconButton?.tooltip || ""}>
                  <InputAdornment position="end">
                    <IconButton
                      onClick={iconButton?.onClick}
                      classes={{
                        root: "sm-textfield-icon",
                      }}
                      edge="end"
                    >
                      {iconButton?.icon}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
                <Tooltip title={iconButton2?.tooltip || ""}>
                  <InputAdornment position="end">
                    <IconButton
                      onClick={iconButton2?.onClick}
                      classes={{
                        root: "sm-textfield-icon",
                      }}
                      edge="end"
                    >
                      {iconButton2?.icon}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              </>
            ),
          }}
        />
        {iconAfter && (!iconAfter.visibleOn || iconAfter.visibleOn(value)) && (
          <span className="ml-2">
            <Tooltip title={iconAfter.title}>
              <IconButton
                onClick={() => {
                  iconAfter.onClick();
                }}
                classes={{ root: "sm-icon-button-small" }}
              >
                {iconAfter.icon}
              </IconButton>
            </Tooltip>
          </span>
        )}
      </div>
      <FormHelperText>{errorMessage}</FormHelperText>
    </FormControl>
  );
}
