import React from "react";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import Logo from "components/Logo/Logo";
import { root } from "ApplicationRoutes";

const RedirectionView = () => {
  let targetBaseUrl = window.location.protocol + "//" + window.location.host;

  return (
    <div className="centered-box">
      <Logo root={root} />
      <Paper classes={{ root: "box-container" }}>
        <div className="box-title">Choose Application</div>

        <div className="mb-20">
          <Button
            fullWidth
            onClick={() => {
              if (targetBaseUrl.includes("7002")) {
                targetBaseUrl = targetBaseUrl.replace("7002", "5002");
              }
              window.location.href = targetBaseUrl + "/app";
            }}
            variant="contained"
          >
            backoffice
          </Button>
        </div>

        <div className="mb-20">
          <Button
            fullWidth
            onClick={() => {
              if (targetBaseUrl.includes("7002")) {
                targetBaseUrl = targetBaseUrl.replace("7002", "8080");
              }
              window.location.href = targetBaseUrl + "/v1";
            }}
            variant="contained"
          >
            V1
          </Button>
        </div>

        <div className="mb-20">
          <Button
            fullWidth
            onClick={() => {
              if (targetBaseUrl.includes("7002")) {
                targetBaseUrl = targetBaseUrl.replace("7002", "1009");
              }
              window.location.href = targetBaseUrl + "/v2";
            }}
            variant="contained"
          >
            V2
          </Button>
        </div>
      </Paper>
    </div>
  );
};

export default RedirectionView;
