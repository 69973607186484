import { removeAccessTokenCookie } from "networking/config";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Logout } from "../../networking/APIs/Logout";

export default function NAVLogout() {
  const history = useHistory();

  useEffect(() => {
    const logout = () => {
      return Logout()
        .catch((err) => console.log(err))
        .then(() => {
          removeAccessTokenCookie();
        });
    };

    logout();
    history.push("/auth");
    //eslint-disable-next-line
  }, []);

  return <div></div>;
}
